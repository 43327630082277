.month {
    width: 325px;
    height: 200px;
    margin: 20px 30px;
    background-color: #e8e8e8 !important;
    border-radius: 30px !important;
}

.card-actions {
    height: 100%;
    word-wrap: break-word;
}

.card-actions ul {
    width: 95%;
}

.card-month {
    font-weight: bolder;
    color: black;
}

.card-state {
    font-weight: lighter;
}

.year {
    color: #003da6;
    font-weight: bolder;
}

.btn-secondary.btn-version,
.btn-light.btn-version {
    display: inline-block;
    height: 20px;
    margin: 0 40px;
    padding-bottom: 20px !important;
    border-radius: 50px !important;
}

.card-actions ul {
    list-style: none;
}

.card-actions ul li::before {
    content: '\2022'; /*Unicode para un bullet de lista*/
    color: black;
    font-size: 20px;
    font-weight: bolder;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.card-actions ul li {
    font-size: 0.8rem;
}

.status-text {
    font-size: 14px;
    font-weight: normal;
}
.month-text {
    font-weight: bolder;
    color: black;
    font-size: 16px;
}
.more-text {
    margin-top: 0.25rem;
    font-size: 12px;
    text-align: end;
}
