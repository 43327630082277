.img {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
}

.wrap {
    width: 100%;
    overflow: hidden;
    background: #fff;
    border-radius: 5px;
    /*-webkit-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 10px 34px -15px rgba(0, 0, 0, 0.24);*/
}

.img,
.login-wrap {
    width: 50%;
}
@media (max-width: 991.98px) {
    .img {
        width: 0px !important;
    }

    .left {
        display: none !important;
    }

    .login-wrap {
        width: 100%;
    }
}

@media (max-width: 767.98px) {
    .wrap .img {
        height: 0px;
    }
    .rojo {
    }
}

.login-wrap {
    position: relative;
    background: #fff h3;
    background-font-weight: 300;
}

/* Borders */
.left {
    /*border-right-style: solid !important;
    border-right-color: coral !important;
    border-right-width: 2px !important;*/
    -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);

    /*
    clip-path: polygon(0 0, 21% 0, 7% 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 78% 100%, 0% 100%);
    */
}

.rigth {
    /*border-right-style: solid !important;
    border-right-color: coral !important;
    border-right-width: 2px !important;*/
    -webkit-clip-path: polygon(14% 0, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(14% 0, 100% 0%, 100% 100%, 0% 100%);
}

.signin-form {
    width: 80% !important;
}

.button-ingreso {
    background-color: #cf0a2c !important;
    color: #ffffff !important;
}

.size-100 {
    width: 100% !important;
}

.size-100s {
    width: 100% !important;
    height: 100% !important;
}
