.form-control {
    border: 1px solid #0d61df !important;
}

.form-custom {
    font: normal normal 600 17px/20px Work Sans !important;
}

.btn-font {
    font: normal normal 600 16px/19px Work Sans !important;
}
