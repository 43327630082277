/* Media Queries */
@media (max-width: 1336px) {
    .bitacora-table {
        width: 100vw;
        z-index: inherit !important;
    }
}

@media (max-width: 767px) {
    .bitacora-form .icon-bitacora {
        width: 20px !important;
        height: 20px !important;
    }

    .bitacora-form .title-bitacora {
        font-size: 30px !important;
    }

    .bitacora-list .title-bitacora {
        font-size: 30px !important;
    }
}

@media (min-width: 540px) and (max-width: 638px) {
    .bitacora-form .icon-bitacora {
        width: 23px !important;
        height: 23px !important;
    }

    .bitacora-form .title-bitacora {
        font-size: 25px !important;
    }

    .bitacora-form .header-bitacora hr {
        margin-top: 5px !important;
    }
}

@media (min-width: 436px) and (max-width: 539px) {
    .bitacora-form .icon-bitacora {
        width: 23px !important;
        height: 23px !important;
    }

    .bitacora-form .title-bitacora {
        font-size: 20px !important;
    }

    .bitacora-form .header-bitacora hr {
        margin-top: 7px !important;
    }
}

@media (max-width: 435px) {
    .bitacora-form .icon-bitacora {
        width: 15px !important;
        height: 15px !important;
    }

    .bitacora-form .title-bitacora {
        width: 70%;
        font-size: 15px !important;
        word-wrap: break-word;
    }

    .bitacora-list .title-bitacora {
        font-size: 20px !important;
    }

    .bitacora-list .header-bitacora hr {
        margin-top: 6px !important;
    }
}

.text-alert-red {
    color: #ff002c;
    font-weight: bold;
}
