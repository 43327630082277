.upload-driver-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
    background-color: #e8e8e8;
    margin: 0;
    margin: auto;
    width: 60%;
    @media (max-width: 576px) {
        width: 100%;
    }
}

.upload-driver-text {
    text-align: center;
    font-size: 1.4rem;
    font-weight: normal;
    @media (max-width: 576px) {
        font-size: 0.8rem !important;
    }
    @media (max-width: 868px) {
        font-size: 1.1rem;
    }
}

.upload-driver-title {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    @media (max-width: 576px) {
        font-size: 0.8rem !important;
    }
    @media (max-width: 868px) {
        font-size: 1.1rem;
    }
}
.upload-driver-icon {
    width: 8rem;
    @media (max-width: 576px) {
        width: 5rem;
    }
}
.uploaded-files-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0;
    margin: auto;
    width: 60%;
    @media (max-width: 576px) {
        width: 100%;
    }
}
