.custom-tabs .react-tabs__tab {
    flex: 1;
}
.custom-tabs .react-tabs__tab-list {
    display: flex;
    flex: 1;
}

.custom-tabs .react-tabs {
    width: 100%;
}

.custom-tabs .react-tabs__tab-list {
    display: flex;
    flex: 1;
}
@media (max-width: 765px) {
    .custom-tabs .react-tabs__tab-list {
        flex-direction: column;
    }
}

.bg-gris {
    background-color: #dbdada !important;
}
.btn-text-center {
    line-height: unset !important;
}

.text-blue {
    color: #003da6;
}
