@media (max-width: 435px) {
    .navbar-button {
        margin-left: 13px;
    }
}

@media (max-width: 765px) {
    .margin-toggle-close {
        margin-left: unset;
    }
}
.main-offset-col-fix {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 768px) {
    .main-offset-col-fix {
        margin-left: 18rem !important;
        -ms-flex: 0 0 65%;
        -webkit-box-flex: 0;
        flex: 0 0 65%;
        max-width: 65%;
    }
}
@media (min-width: 992px) {
    .main-offset-col-fix {
        margin-left: 22rem !important;
        -ms-flex: 0 0 65%;
        -webkit-box-flex: 0;
        flex: 0 0 65%;
        max-width: 65%;
    }
}
@media (min-width: 1200px) {
    .main-offset-col-fix {
        margin-left: 22rem !important;
        -ms-flex: 0 0 70%;
        -webkit-box-flex: 0;
        flex: 0 0 70%;
        max-width: 70%;
    }
}
@media (min-width: 1400px) {
    .main-offset-col-fix {
        margin-left: 22rem !important;
        -ms-flex: 0 0 75%;
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }
}

@media (min-width: 1700px) {
    .main-offset-col-fix {
        max-width: 1600px !important;
    }
}

.second-offset-col-fix {
    -ms-flex: 0 0 100%;
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

@media (min-width: 768px) {
    .second-offset-col-fix {
        margin-left: 6.5rem !important;
        -ms-flex: 0 0 87%;
        -webkit-box-flex: 0;
        flex: 0 0 87%;
        max-width: 87%;
    }
}
@media (min-width: 992px) {
    .second-offset-col-fix {
        -ms-flex: 0 0 90%;
        -webkit-box-flex: 0;
        flex: 0 0 90%;
        max-width: 90%;
    }
}
@media (min-width: 1200px) {
    .second-offset-col-fix {
        -ms-flex: 0 0 90%;
        -webkit-box-flex: 0;
        flex: 0 0 90%;
        max-width: 90%;
    }
}
@media (min-width: 1400px) {
    .second-offset-col-fix {
        -ms-flex: 0 0 92%;
        -webkit-box-flex: 0;
        flex: 0 0 92%;
        max-width: 92%;
    }
}

@media (min-width: 2300px) {
    .col-offset-in-xl {
        max-width: 2000px;
        margin: auto;
    }
}
