.nav-item-dropdown:focus {
    background-color: #dbdbdb !important;
}

.toggle-button {
    position: absolute;
    top: 27px;
    cursor: pointer;
}

/* Media queries */
@media (max-width: 767px) {
    nav {
        margin-top: 0.25rem !important;
    }

    .toggle-button {
        right: 87vw;
    }
}

@media (max-width: 550px) {
    .toggle-button {
        right: 85vw;
    }
}

@media (max-width: 435px) {
    .toggle-button {
        right: 83vw;
    }

    .navbar-gyt {
        margin-right: 25px;
    }
}

@media (max-width: 379px) {
    .toggle-button {
        right: 81vw;
    }
}