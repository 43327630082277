.notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 330px !important;
    padding: 0px !important;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
}

.notification {
    display: flex;
    box-sizing: border-box;
    padding: 15px 15px 15px 58px !important;
    border-end-start-radius: 0.5rem !important;
    border-start-start-radius: 0.5rem !important;
    margin-right: 0px !important;
    color: #fff !important;
    background-color: #fff;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 1 !important;
    margin-top: 15px;

    border-top: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
}

.notification-message {
    color: #fff !important;
}

.notification-success {
    background-color: #2fa32a !important;
    border-color: #2fa32a !important;
}

.notification-info {
    background-color: #003da6 !important;
    border-color: #003da6 !important;
}

.notification-error {
    background-color: #cf0a2c;
    border-color: #cf0a2c;
}

.notification-warning {
    background-color: #eb8534 !important;
    border-color: #eb8534 !important;
}
