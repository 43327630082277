@media (max-width: 575.98px) {
    .row {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}
@media (max-width: 767.98px) {
    .rojo {
        background-color: transparent !important;
    }
}

.login-overlay .panel-body {
    min-height: 244px;
}
.has-feedback {
    position: relative;
}
.form-control-feedback {
    right: 10px;
    left: auto;
    top: 10px;
    position: absolute;
}
.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.card-login {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    padding: 2rem;
}
.bienvenida {
    color: white;
    font-weight: bold;
}
.buttons-box {
    display: flex;
    align-items: center;
    justify-content: center;
}
.blue-gradient-bg {
    height: 100vh;
    background: #007bff;
    background: transparent linear-gradient(180deg, #5592e9 0%, #003da6 100%) 0% 0% no-repeat padding-box !important;
}

.div-bord-rigth {
    clip-path: polygon(14% 0, 21% 0, 7% 100%, 0% 100%);
}

.div-bord {
    clip-path: polygon(82% 0, 100% 0%, 75% 100%, 56% 100%);
}

.div-board-primary {
    z-index: 2 !important;
    float: left !important;
    background-color: #cf0a2c !important;
    height: 100% !important;
    width: 100% !important;
}

.rojo {
    width: 230px;
    height: 100% !important;
    background: #cf0a2c;
    z-index: 2; /*El z-index es mayor al de la caja azul por lo que se muestra primero que la caja roja*/
    position: absolute;
    margin-left: -235px !important;
    margin-top: -48px !important;
}

.center-bx {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100% !important;
}

.transparencia {
    background-color: transparent !important;
}
