.stepper-container {
    display: flex;
    background-color: #e8e8e8;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.stepper-title {
    display: flex;
    flex: 3;
    flex-direction: column;
    box-sizing: border-box;
}

.stepper-step {
    font-size: 1rem;
    color: #191919;
}
.stepper-step-name {
    font-size: 0.8rem;
    color: #191919;
    font-weight: normal;
}
.stepper-buttons {
    display: flex;
    flex: 1;
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
    align-items: center !important;
}
.stepper-container .progress-bar {
    background-color: #003da6;
}
.stepper-container .progress {
    height: 0.25rem;
    width: 100%;
    @media (min-width: 766px) {
        width: 75% !important;
    }
}
