@media screen and (max-width: 480px) {
    .verify-login-box {
        width: 100% !important;
        margin: 2rem;
    }
    .verify-login-box img {
        width: 12rem !important;
    }
}

.verify-login-container {
    background: transparent linear-gradient(180deg, #5592e9 0%, #003da6 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verify-login-box {
    width: 30rem;
    height: 70%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 40px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.verify-login-box p {
    color: #3b3b41;
    font-size: 20px;
    font-weight: bold;
}
.verify-login-box img {
    width: 15rem;
}

.verify-login-box .progress {
    width: 75%;
}

.custom-progress-bar.progress .progress-bar {
    background-color: #003da6;
    height: 0.5rem;
}
.custom-progress-bar.progress {
    border-radius: 0.01rem;
    height: 0.5rem;
}
