.upload-driver-text {
    text-align: center;
    font-size: 1.4rem;
    font-weight: normal;
    @media (max-width: 576px) {
        font-size: 0.8rem !important;
    }
    @media (max-width: 868px) {
        font-size: 1.1rem;
    }
}

.upload-driver-title {
    text-align: center;
    font-size: 1.4rem;
    font-weight: bold;
    @media (max-width: 576px) {
        font-size: 0.8rem !important;
    }
    @media (max-width: 868px) {
        font-size: 1.1rem;
    }
}
.upload-text-title {
    display: flex;
    width: 60%;
    margin: auto;
    @media (max-width: 576px) {
        width: 100%;
    }
}
