.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10rem;
    background-color: #e8e8e8;
    margin: 0;
    margin: auto;
    width: 60%;
    @media (max-width: 576px) {
        width: 100%;
    }
}

.upload-text {
    text-align: center;
    font-size: 1.4rem;
    font-weight: normal;
    @media (max-width: 576px) {
        font-size: 0.8rem !important;
    }
    @media (max-width: 868px) {
        font-size: 1.1rem;
    }
}

.uploaded-file-icon {
    width: 17px;
}
.uploaded-file-text {
    font-size: 15px;
    font-weight: bold;
}
