.swal2-container.swal2-center > .swal2-popup {
    border-top: solid 2rem #003da6;
}
.swal2-icon {
    border-color: transparent !important;
}
.swal2-title {
    font-family: Work Sans;
    padding-bottom: 1em;
    text-align: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: #000000;
}
.swal2-html-container {
    font-family: Work Sans;
    color: #000000;
    font-weight: normal;
    font-size: 1.5rem;
    padding-bottom: 1em;
}
.swal2-styled.swal2-cancel {
    background-color: #3b3b41 !important;
    border-radius: 5px;
    width: 12rem;
}
.swal2-styled.swal2-confirm {
    background-color: #cf0a2c !important;
    border-radius: 5px;
    width: 12rem;
}
.swal2-styled {
    margin: 0.5em;
}
.swal2-icon-content img {
    max-width: 5rem;
}
.swal2-icon {
    margin: 3em auto 2em !important;
}
.swal2-input[type='number'] {
    max-width: unset !important;
}
.swal2-input-label {
    padding-inline: 2rem;
}
