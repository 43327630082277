.PanelButtonsDistribucionSoporte {
    text-align: right !important;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    margin-right: 5px !important;
    color: darkblue !important;
}

.text-cta {
    font: normal normal bold 16px/19px Work Sans !important;
    letter-spacing: 0px !important;
    color: #003da6 !important;
    margin-left: -5px !important;
    margin-bottom: 0px;
}

.text-percent {
    color: #003da6 !important;
    font: normal normal bold 16px/19px Work Sans !important;
    margin-right: -5px !important;
}

.text-percent-error {
    color: #cf0a2c !important;
    font: normal normal bold 16px/19px Work Sans !important;
    letter-spacing: 0px !important;
    margin-right: 10px !important;
}

.container-text-cta {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}
