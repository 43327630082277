.submenu-header {
    border-start-start-radius: 0.5rem;
    border-start-end-radius: 0.5rem;
}

.submenu-list {
    list-style: none;
    max-height: 35rem;
    overflow-y: scroll;
}
.submenu-item {
    padding-left: 3rem;
    padding-block: 0.75rem;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}
.submenu-item a {
    color: #404040;
    text-decoration: unset;
}
.submenu-item a:hover {
    color: #404040;
    text-decoration: unset;
}
.submenu-item:hover {
    background-color: #b7b6b6;
}
/* Tamaño del scroll */
.submenu-list::-webkit-scrollbar {
    width: 5px;
}

.submenu-list::-webkit-scrollbar-thumb {
    background: #3b3b41;
    border-radius: 10px;
}

.submenu-list::-webkit-scrollbar-thumb:active {
    background-color: #999999;
}

.submenu-container {
    position: static;
    background-color: white;
    min-width: 8rem;
    border-radius: unset;
}
.submenu-header {
    display: none;
}

@media (min-width: 768px) {
    .contenedor-submenu {
        position: absolute;
        right: -100vw;
        top: 0;
        height: 100vh;
        width: 100vw;
    }
    .submenu-container {
        position: relative;
        z-index: 1000;
        background-color: white;
        max-width: 15rem;
        top: 10rem;
        border-radius: 0.5rem;
    }

    .submenu-container-config {
        position: relative;
        z-index: 1000;
        background-color: white;
        max-width: 15rem;
        top: 25rem;
        border-radius: 0.5rem;
    }
    .submenu-item {
        padding-left: 0.5rem;
        padding-block: 0.25rem;
        cursor: pointer;
    }
    .submenu-header {
        display: flex;
    }
}
