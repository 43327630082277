.small-box {
    background-color: #e8e8e8;
    border-radius: 11px;
    padding: 10px 15px 10px 15px;
    color: #3b3b41;
    font-weight: bold;
    max-width: 25rem;
}

.small-white-box {
    padding: 10px 15px 10px 15px;
    color: #003da6;
}
.small-white-box .text-1 {
    font: normal normal bold 18px/22px Work Sans;
}
.small-white-box .text-2 {
    font: normal normal bold 28px/33px Work Sans;
}
.custom-table-projects.table thead th {
    border-bottom: unset;
}
.scroll-table-compuestos .react-bs-table-container .table {
    min-width: 1000px !important;
}
.custom-number-field {
    height: calc(1.5rem + 2px) !important;
    width: 9rem !important;
}

@media screen and (max-width: 576px) {
    .small-white-box .text-1 {
        font: normal normal bold 15px/20px Work Sans;
    }
    .small-white-box .text-2 {
        font: normal normal bold 20px/25px Work Sans;
    }
    .small-box .text-1 {
        font: normal normal bold 15px/20px Work Sans;
    }
}

@media screen and (max-width: 776px) and (min-width: 577px) {
    .small-white-box .text-1 {
        font: normal normal bold 17px/22px Work Sans;
    }
    .small-white-box .text-2 {
        font: normal normal bold 22px/27px Work Sans;
    }
    .small-box .text-1 {
        font: normal normal bold 17px/22px Work Sans;
    }
}

.custom-table-projects.table tbody tr {
    background-color: #e8e8e8 !important;
    border: 1px solid #191919 !important;
}
