@media (max-width: 438px) {
    .historical-entry .month {
        width: 80%;
    }
}

.text-status {
    font-weight: normal;
    font-size: 0.8rem;
}
.text-icon {
    width: 15px;
}
.text-alert {
    font-weight: normal;
    font-size: 1rem;
}

.card-state-1 {
    background-color: #ffe3a6 !important;
}

.card-state-2 {
    background-color: #dce9fe !important;
}

.icon-lg {
    width: 2.5rem;
    height: 2.5rem;
}
