/* * PAGINACION * */

.pagination .page-link {
    margin-left: 2px !important;
    border-radius: 5px !important;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    color: #ffffffff !important;
    background-color: #3b3b41 !important;
    border: none;
    margin: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    transition: all 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
}

.page-link:focus,
.page-link:hover {
    color: #fff !important;
    background-color: #3b3b41 !important;
    border-color: #dfe1e3 !important;
}

.active.page-item .page-link,
.active.page-item .page-link {
    color: #fff !important;
    background-color: #003da6 !important;
    border-color: #003da6 !important;
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    box-shadow: 0 0 0 0.2rem #ffd000(0, 123, 255, 0.25) !important;
}
.react-bootstrap-table-page-btns-ul.pagination {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
