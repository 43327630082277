.gestion-item {
    background-color: #3367b2;
    color: #fff;
    font-weight: bold;
    border-radius: 0.3rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 15px;
}
.gestion-item.select {
    background-color: #cf0a2c;
}

.draggable-portal {
    position: absolute;
    pointer-events: none;
}
