.cabecera-title {
    font-size: 1rem;
    font-weight: bolder;
    line-height: 1;
    margin: 0;
    padding: 0;
    color: #191919;
    margin-top: 0.35rem;
    margin-bottom: 0.75rem;
}
/* Media para el title */
@media (min-width: 768px) {
    .cabecera-title {
        font-size: 1rem;
        margin-top: 0.2rem;
        margin-bottom: 0.75rem;
    }
    .dropdown-cabecera {
        height: 31.2px;
    }
}
@media (min-width: 992px) {
    .cabecera-title {
        font-size: 1.15rem;
        margin-bottom: 0.75rem;
    }
    .dropdown-cabecera {
        height: 33.2px;
    }
}
@media (min-width: 1200px) {
    .cabecera-title {
        font-size: 1.625rem;
        margin-bottom: 0.75rem;
    }
    .dropdown-cabecera {
        height: 41.2px;
    }
}

.icono-navegacion {
    font-size: 35px !important;
    color: #191919 !important;
    margin-top: -0.25rem;
}

.breadcrumb-gyt {
    z-index: 1700;
}

.breadcrumb-title {
    font-size: 26px !important;
}

.breadcrumb-icon {
    width: 25px;
    height: 25px;
}

.breadcrumb-button {
    width: 24px;
    height: 24px;
}

.breadcrumb-dropdown .breadcrumb-dropdown-toggle {
    display: flex;
    padding: 0 5px;
    margin: 0;
    align-items: center;
}

.breadcrumb-dropdown-menu {
    height: 250px;
    overflow-y: auto;
}

/* Media Queries */
@media (min-width: 767px) and (max-width: 804px) {
    .breadcrumb-icon {
        width: 20px;
        height: 20px;
    }

    .breadcrumb-title {
        font-size: 20px !important;
    }

    .breadcrumb-gyt {
        margin-bottom: 6px;
    }
}

@media (min-width: 625px) and (max-width: 766px) {
    .breadcrumb-gyt {
        margin-bottom: 4px;
    }
}

@media (min-width: 517px) and (max-width: 624px) {
    .breadcrumb-icon {
        width: 15px;
        height: 15px;
    }

    .breadcrumb-title {
        font-size: 20px !important;
    }

    .breadcrumb-gyt {
        margin-bottom: 10px;
    }
}

@media (max-width: 516px) {
    .breadcrumb-icon {
        width: 10px;
        height: 10px;
    }

    .breadcrumb-title {
        font-size: 15px !important;
    }

    .breadcrumb-gyt {
        margin-bottom: 15px;
    }
}

@media (max-width: 410px) {
    .breadcrumb-icon {
        display: none;
    }

    .breadcrumb-gyt {
        margin-bottom: 15px;
    }
}

/* Media Queries para Menú abierto */
@media (max-width: 991px) {
    .breadcrumb-gyt-open {
        margin-bottom: 3px;
    }

    .breadcrumb-gyt-open .breadcrumb-title {
        font-size: 15px !important;
    }

    .breadcrumb-gyt-open .breadcrumb-icon {
        width: 15px;
        height: 15px;
    }
}

@media (max-width: 810px) {
    .breadcrumb-gyt-open .breadcrumb-icon {
        width: 10px;
        height: 10px;
    }
}
.btn-cancelar {
    color: #3b3b41;
    background: #e8e8e8 !important;
    margin-bottom: 0.75rem;
    padding: 0.25rem 0.75rem !important;
}

.btn-cancelar img {
    width: 12px;
    height: 12px;
    margin-right: 0.25rem;
}
