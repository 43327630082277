.custom-scroll-step-10 .react-bs-table-container.scroll-table .table {
    min-width: 1250px !important;
}
.custom-scroll-step-10 .table-bordered td {
    border: unset;
}
.row-edit-icon {
    width: 1.5rem;
}

.custom-big-scroll-step-10 .react-bs-table-container.scroll-table .table {
    min-width: 25000px !important;
}
.custom-small-scroll-step-10 .react-bs-table-container.scroll-table .table {
    min-width: 1550px !important;
}

.custom-scroll-final-step-10 .react-bs-table-container.scroll-table .table {
    min-width: 1800px !important;
}
