.custom-scroll-step-12 .react-bs-table-container.scroll-table .table {
    min-width: 2200px !important;
}

.total-text {
    color: black;
    margin-left: 5px;
}

.total-container {
    margin-bottom: -30px !important;
    text-align: right !important;
    margin-top: -50px;
}
