.text-filter {
    color: var(--unnamed-color-191919);
    text-align: left;
    font: normal normal 600 17px/20px Work Sans;
    letter-spacing: 0px;
    color: #191919;
    opacity: 1;
    margin-top: 10px !important;
    text-align: center !important;
    width: 100% !important;
    /*margin-left: 10% !important;*/
}

.hr-gray {
    background: #e8e8e8b0 0% 0% no-repeat padding-box;
    height: 10px !important;
}

.container-filter {
    display: inline-block !important;
}

.btn-blue {
    color: white !important;
    font: normal normal 600 14px Work Sans !important;
    background-color: #003da6 !important;
    margin-left: 10px !important;
}

.feli-control {
    -moz-box-align: center;
    align-items: center;
    background-color: white;
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
}
/*
.react-select {
    z-index: 2000 !important;
}
/*
.react-bs-container-header {
    z-index: 2000;
    overflow: inherit !important;
}*/
/*
.my-class {
    overflow: inherit !important;
}

.custom-table .react-bs-container-body {
    z-index: -1 !important;
    overflow: inherit !important;
}

/* ------------------------ */

/*---------------*/
INPUT[type='checkbox'] {
    width: 20px;
    height: 20px;
}

INPUT[type='checkbox']:checked {
    background-color: #3367b2;
}
/*
INPUT[type='checkbox']:focus {
    outline: 1px solid rgba(0, 0, 0, 0.2);
}

INPUT[type='checkbox'] {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #3b3b41;
    border-radius: 3px;
    opacity: 1;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: relative;
    top: 5px;
}

INPUT[type='checkbox']:checked {
    background-color: #3367b2;
    background: #3367b2 url('../../../../../../../assets/img/25-CheckB_Mesade\ trabajo1.svg') 0px 0px no-repeat;
}*/

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
    z-index: 2;
    color: #fff;
    background-color: #71889b;
    border-color: #337ab7;
    cursor: default;
}
/*---------------*/

.general-container {
    margin-bottom: 15px !important;
}

.general-container-filter1 {
    display: inline-block !important;
    width: 70% !important;
}

.general-container-filter2 {
    display: inline-block !important;
    width: 30% !important;
}

.container-filter-a1 {
    max-width: 325px !important;
    display: inline-block !important;
    width: 40% !important;
}

.container-filter-b1 {
    max-width: 200px !important;
    display: inline-block !important;
    width: 40% !important;
}

.container-filter-c1 {
    display: inline-block !important;
    width: 20% !important;
}

.general-container-filter {
    width: 100% !important;
}

.container-filter-a {
    display: inline-block !important;
    width: 15% !important;
}

.container-filter-b00 {
    display: inline-block !important;
    width: 0% !important;
}

.container-filter-b0 {
    display: inline-block !important;
    width: 12% !important;
}

.container-filter-ba {
    display: inline-block !important;
    width: 12% !important;
}

.container-filter-bb {
    display: inline-block !important;
    margin-left: 10px !important;
    width: 18% !important;
}

.container-filter-bc {
    display: inline-block !important;
    margin-left: 10px !important;
    width: 18% !important;
}

.container-filter-c {
    display: inline-block !important;
    text-align: right !important;
    margin-top: 5px !important;
    width: 5% !important;
}

/*---------*/

@media screen and (min-width: 1080.99px) {
    .container-filter-b0 {
        margin-right: 5px !important;
        display: inline-block !important;
        width: 20% !important;
    }
}

@media screen and (min-width: 800px) and (max-width: 1080px) {
    .general-container {
        display: inline-block !important;
        width: 100% !important;
        margin-bottom: 15px !important;
    }

    .general-container-filter1 {
        display: inline-block !important;
        width: 70% !important;
    }

    .general-container-filter2 {
        display: inline-block !important;
        width: 30% !important;
    }

    .container-filter-a1 {
        width: 40% !important;
    }

    .container-filter-b1 {
        width: 25% !important;
    }

    .container-filter-a {
        width: 100% !important;
        margin-bottom: 5px !important;
    }
    .container-filter-b00 {
        width: 0% !important;
    }
    .container-filter-b0 {
        margin-left: -4px !important;
        width: 50% !important;
    }

    .container-filter-ba {
        margin-left: 4px !important;
        width: 50% !important;
    }
    .container-filter-bb {
        margin-top: 5px !important;
        margin-left: -4px !important;
        width: 50% !important;
    }
    .container-filter-bc {
        margin-top: 5px !important;
        margin-left: 2px !important;
        width: 50% !important;
    }
    .container-filter-c {
        text-align: right !important;
        margin-top: 10px !important;
        width: 100% !important;
    }
    .text-filter {
        text-align: left !important;
        margin-left: 5px !important;
        font: normal normal 600 15px/17px Work Sans;
    }
    .general-container-filter .btn-blue {
        margin-left: 2px !important;
    }
}

@media screen and (min-width: 580px) and (max-width: 799.99px) {
    .general-container-filter1 {
        width: 100% !important;
    }

    .general-container-filter2 {
        margin-top: 15px !important;
        width: 100% !important;
    }

    .container-filter-a1 {
        width: 50% !important;
    }

    .container-filter-b1 {
        width: 25% !important;
    }

    .container-filter-c1 {
        width: 25% !important;
    }

    .container-filter-a {
        width: 100% !important;
    }

    .container-filter-b0 {
        margin-left: 10px !important;
        width: 50% !important;
        margin-bottom: 5px !important;
    }

    .container-filter-ba {
        margin-left: 15px !important;
        width: 45% !important;
        margin-bottom: 5px !important;
    }
    .container-filter-bb {
        width: 98% !important;
    }
    .container-filter-bc {
        margin-top: 5px !important;
        width: 98% !important;
    }
    .container-filter-c {
        margin-top: 5px !important;
        margin-left: -10px !important;
        text-align: right !important;
        width: 100% !important;
    }
    .text-filter {
        text-align: left !important;
        margin-left: 5px !important;
        font: normal normal 600 15px/17px Work Sans;
    }
}

@media screen and (min-width: 0px) and (max-width: 579.99px) {
    .general-container-filter1 {
        width: 100% !important;
    }

    .general-container-filter2 {
        margin-top: 15px !important;
        width: 100% !important;
    }

    .container-filter-a1 {
        width: 100% !important;
    }

    .container-filter-b1 {
        width: 100% !important;
        max-width: 100% !important;
    }

    .container-filter-c1 {
        margin-top: 5px !important;
        text-align: right !important;
        width: 100% !important;
    }

    .container-filter-a {
        width: 100% !important;
    }
    .container-filter-ba {
        width: 100% !important;
    }
    .container-filter-bb {
        margin-left: -0px !important;
        margin-top: 5px !important;
        width: 100% !important;
    }
    .container-filter-bc {
        margin-left: 10px !important;
        margin-top: 5px !important;
        width: 100% !important;
    }

    .container-filter-b0 {
        margin-bottom: 5px !important;
        margin-top: 5px !important;
        width: 100% !important;
    }

    .container-filter-c {
        margin-top: 5px !important;
        margin-left: 0px !important;
        text-align: right !important;
        width: 100% !important;
    }
    .text-filter {
        text-align: left !important;
        margin-left: 5px !important;
        font: normal normal 600 15px/17px Work Sans;
    }
}

.asignado {
    font: normal normal normal 13px/15px Work Sans;
    letter-spacing: 0px;
    color: #003da6;
    opacity: 1;
}

.no_asignado {
    font: normal normal normal 13px/15px Work Sans;
    color: #eb8534;
    opacity: 1;
}

/*

    background: transparent url('../../../../../../../assets/img/25-CheckB_Mesade\ trabajo1.svg') 0% 0% no-repeat
        padding-box;
        */
