.custom-select-groups {
    width: 1rem !important;
}
/* .custom-dis-actividades .react-select__control {
    min-height: 25px !important;
    max-height: 25px !important;
    align-content: center !important;
} */

.react-select__menu {
    z-index: 20000 !important;
    overflow: unset !important;
}

.custom-column-select {
    overflow: unset !important;
}
.custom-select-table {
    height: calc(1.5rem + 2px);
    padding: 0.1rem 1.75rem 0.1rem 0.65rem;
    line-height: 1.5;
    color: #3b3b41;
    border: 1px solid #003da6;
    background-color: #fff;
    font-weight: 300;
    font-size: 0.8125rem;
    -webkit-transition: box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
        border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    transition: box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06), border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
    border-radius: 5px;
    outline: 0;
}

.custom-select-table:focus {
    border-color: #003da6;
    box-shadow: 0 0.313rem 0.719rem rgba(0, 123, 255, 0.1), 0 0.156rem 0.125rem rgba(0, 0, 0, 0.06);
    border-radius: 0.35rem;
    outline: 0;
}

option:hover {
    background-color: #003da6 !important;
}

.hide-select-all th .react-bs-select-all {
    display: none;
}
.scroll-table-actividades .react-bs-table-container .table {
    min-width: 1500px !important;
}
