.rc-tabs-tab {
    color: black;
}
.rc-tabs-nav-wrap {
    background-color: #f2f2f2;
}
.rc-tabs-tab-active,
.rc-tabs-tab-active {
    color: #fff !important;
    font-weight: bold !important;
    background-color: #003da6;
    border-bottom: 2px solid;
    border-radius: 5px;
}

.rc-tabs-ink-bar {
    background-color: #003da6;
}

.rc-tabs-top .rc-tabs-tab {
    font-weight: 400;
    min-width: 25rem;
    margin: 0px;
    padding: 10px 50px 10px 50px;
    color: #000000;
    text-align: center;
}
.rc-tabs-tab:hover {
    color: #003da6;
}
.rc-tabs-top {
    border-bottom: none;
}

.rc-tabs-bottom .rc-tabs-tab {
    padding-left: 30px;
    padding-right: 30px;
}

.rc-tabs-ink-bar.rc-tabs-ink-bar-animated {
    display: none !important;
    background-color: transparent !important;
}
