aside {
    background-color: #dbdbdb !important;
    width: 100%;
    box-shadow: none !important;
    transition: all 0.2s !important;
}

.nav-link {
    background-color: inherit !important;
}

.nav-link.active .item-icon-wrapper {
    width: 40px;
    height: 40px;
    background-color: #ffb819;
    border-radius: 7px;
}

.nav-link.active-open .item-container {
    padding: 0.2rem;
    background-color: #e32736;
    align-self: flex-end;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    /* border-bottom-left-radius: 5px; */
}

.main-sidebar {
    width: 90%;
}

.nav-gyt {
    width: 90%;
    height: 150px !important;
}

.nav-gyt-open {
    height: 250px !important;
}

.nav-gyt .background {
    height: 150px;
    align-self: flex-start;
    width: 70%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.nav-gyt .background-open {
    height: 250px;
}

.offset-12 {
    margin-left: 100%;
}

.nav-item span {
    font-size: 15px;
}

#main-logo {
    transition: all 0.2s linear;
}

/* Media Queries */
@media (min-width: 571px) {
    .offset-sm-12 {
        margin-left: 100%;
    }
}
.nav-link.active span {
    color: #ffb819;
}
.nav-link.active-open span {
    color: #ffb819;
}
.sidebar-column {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    @media (max-width: 571px) {
        max-height: unset;
    }
}
.icon-sidebar {
    width: 30px;
    height: 30px;
}
.logo-gyt-x {
    width: 18rem !important;
    @media (max-width: 1600px) {
        width: 17rem !important;
    }
    @media (max-width: 1200px) {
        width: 16rem !important;
    }
    @media (max-width: 992px) {
        width: 14rem !important;
    }
}
.sidebar-cerrado {
    width: 6.5rem;
}

@media (min-width: 768px) {
    .sidebar-offset-col-fix {
        width: 18rem !important;
    }
}
@media (min-width: 992px) {
    .sidebar-offset-col-fix {
        width: 22rem !important;
    }
}

@media (min-width: 1200px) {
    .sidebar-offset-col-fix {
        width: 22rem !important;
    }
}
