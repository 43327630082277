.icono-carga {
    width: 10rem;
    @media (max-width: 767px) {
        width: 6rem;
    }
}
.column-data-icon {
    width: 1.5rem;
}
.success-icon {
    width: 7rem;
    @media (max-width: 576px) {
        width: 5rem !important;
    }
    @media (max-width: 767px) {
        width: 7rem;
    }
}
.remove-style {
    font-weight: unset !important;
    font-size: unset !important;
}
.react-date-picker__wrapper {
    border: none !important;
}

.icon-cabecera-informe {
    width: 24px;
    height: 24px;
    margin-top: 0.35rem;
}
.cabecera-text {
    color: #191919;
    text-align: end !important;
    margin-top: 0.25rem;
    font-size: 1.35rem;
}

.nombre-tabla {
    text-align: left;
    font: normal normal bold 20px/24px Work Sans;
    letter-spacing: 0px;
    color: #3b3b41;
    opacity: 1;
}

.td-column-function-odd-example {
    background-color: #e8e8e8;
    border: 1px solid #3b3b41 !important;
    border-radius: 3px !important;
}

.td-column-function-even-example {
    background-color: #ffffff;
    border: 1px solid #3b3b41 !important;
    border-radius: 3px !important;
}

.alert-error {
    margin-left: -12px !important;
    background: #ff002c 0% 0% no-repeat padding-box !important;
    color: #ffffff !important;
    width: 105%;
    height: 105%;
}

.alert-warning {
    margin-left: -12px !important;
    background: #ffb819 0% 0% no-repeat padding-box !important;
    color: #191919 !important;
    /*color: #ffffff !important;*/
    width: 105%;
    height: 105%;
}

.margin-results {
    margin-left: 25% !important;
}
.success-text {
    color: #3b3b41;
    font-weight: bold;
    font-size: 1.5rem;
}
