/* Tables */
.table thead th,
.table tbody tr {
    border: none;
}

.table thead {
    font-weight: bolder;
}

thead {
    background-color: #3367b2;
    color: #fff;
}
thead:hover {
    color: #fff;
}

.page-item {
    box-shadow: none !important;
}

.scoped-table .react-bs-table-pagination .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* Media Queries */

@media (max-width: 1336px) {
    .scoped-table {
        width: 100vw;
    }
}
.react-bs-container-header {
    border-radius: 5px;
}

.scroll-table .react-bs-container-body {
    overflow-x: scroll !important;
}

.react-bs-table-container.scroll-table .table {
    min-width: 3200px !important;
}
.react-bs-table-expand-cell {
    border-right: none;
    overflow: inherit !important;
}

.td-column-function-odd-example {
    background-color: #e8e8e8;
    border: 1px solid #3b3b41 !important;
    border-radius: 3px !important;
}

.td-column-function-even-example {
    background-color: #ffffff;
    border: 1px solid #3b3b41 !important;
    border-radius: 3px !important;
}
.table-bordered td {
    border: none !important;
}
