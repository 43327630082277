.react-tabs__tab--selected {
    color: #ffffff !important;
    background-color: #003da6 !important;
    border-radius: 5px;
}
.react-tabs__tab {
    color: #3b3b41 !important;
    background-color: #e8e8e8 !important;
    border-radius: 5px;
    text-align: center;
}

.react-tabs__tab.react-tabs__tab--selected {
    color: #ffffff !important;
    font-weight: bold;
    background-color: #003da6 !important;
    border-radius: 5px;
}
.react-tabs__tab-list {
    border-bottom: none !important;
}
